// Function to get URL parameter by name
function getUrlParameter(name) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// Function to set cookie
function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
// Function to get cookie value by name
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
// Function to send the AJAX request
function sendEventToFacebook() {
    // Check if the event has already been sent
    var fbClickId = getCookie('fbclid');
    var fbp = getCookie('_fbp');
    if (!sessionStorage.getItem('eventSentToFacebook')) {
        $.ajax({
            url: '/sendFacebookEvent',
            type: 'POST',
            dataType: 'json',
            data: {
                event_name: 'ClientVisit',
                fbclid: fbClickId,
                fbp: fbp
            },
            success: function(response) {
                // Set flag in sessionStorage to indicate that the event has been sent
                if (response && response.success) {
                    sessionStorage.setItem('eventSentToFacebook', true);
                }
            },
            error: function(xhr, status, error) {

            }
        });
    }
}

// Check if fbclid exists in URL and set it in a cookie
window.onload = function() {
    var fbClickIdInput = document.getElementById('fbClickId');
    var fbpIdInput = document.getElementById('fbp');
    var fbclid = getUrlParameter('fbclid');

    if (fbclid) {
        setCookie('fbclid', fbclid, 1); // Set cookie for 1 day
    }

    if (fbClickIdInput) {
        // Get fbclid from cookie and set its value to the input element
        var fbclidCookie = getCookie('fbclid');

        if (fbclidCookie) {
            fbClickIdInput.value = fbclidCookie;
        }
    }

    if (fbpIdInput) {
        var fbpCookie = getCookie('_fbp');

        if (fbpCookie) {
            fbpIdInput.value = fbpCookie;
        }
    }
    sendEventToFacebook();
};
